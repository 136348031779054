import ProGalleryStore from './ProGalleryStore';
import { experimentsWrapper } from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
import translationUtils from '@wix/photography-client-lib/dist/src/utils/translationUtils';
import getArtStoreApi from '../viewerScript/ArtStore/artStoreApi';
import directFullscreenHelper from './helpers/directFullscreenHelper';

export default class CommonViewerScript {
  constructor(
    context,
    controllerConfigs,
    APP_DEFINITION_ID,
    GALLERY_WIDGET_ID,
  ) {
    this.context = context.getContext();
    this.pgStore = null;
    this.controllerConfigs = controllerConfigs;
    this.wixCodeApi = controllerConfigs.wixCodeApi;
    this.sentryReporter = context.getSentry();
    this.initPgStore(APP_DEFINITION_ID, GALLERY_WIDGET_ID);
    this.setWarmupDataIfNeeded();
  }

  setContext(context) {
    this.context = context;
  }

  setSentryReporter(sentryReporter) {
    this.sentryReporter = sentryReporter;
  }

  getPgStore() {
    return this.pgStore;
  }

  getClickedIdx() {
    return this.getPgStore().clickedIdx;
  }

  getbaseUrls() {
    return this.getContext().baseUrls;
  }

  getWarmupData() {
    return {
      items: this.getItems(),
      totalItemsCount: this.getPgStore().totalItemsCount,
      experiments: experimentsWrapper.experiments,
    };
  }

  getDirectFullscreenItem() {
    return this.getPgStore().getDirectFullscreenItem();
  }

  setMetaTagsInSSRIfNeeded() {
    const directFullscreenItem = this.getDirectFullscreenItem();
    if (this.isSSR && directFullscreenItem && directFullscreenItem.itemId) {
      const pgStore = this.getPgStore();
      const wixCodeApi = this.wixCodeApi;
      const currentDirectFullscreenUrl = pgStore.getCurrentDirectFullscreenUrl(
        wixCodeApi,
      );
      pgStore.setMetaTagsInSSR(
        directFullscreenItem,
        pgStore.getPageUrl(wixCodeApi),
        currentDirectFullscreenUrl,
        wixCodeApi,
      );
    }
  }

  isInSEO() {
    const wixCodeApi = this.wixCodeApi;
    return (
      typeof wixCodeApi.seo.isInSEO === 'function' && !!wixCodeApi.seo.isInSEO()
    );
  }

  getItemsPromise() {
    return this.getPgStore().loadInitialItems(this.isSSR());
  }

  getQueryParams() {
    return this.wixCodeApi.location.query;
  }

  getLocaleFromContext() {
    const { scopedGlobalSdkApis } = this.context;
    if (
      scopedGlobalSdkApis &&
      scopedGlobalSdkApis.site &&
      scopedGlobalSdkApis.site.language
    ) {
      return scopedGlobalSdkApis.site.language;
    } else {
      return 'en';
    }
  }

  getCommonGalleryProps(isStore) {
    const pgStore = this.getPgStore();
    const wixCodeApi = this.wixCodeApi;
    const siteUrl = ProGalleryStore.getSiteUrl(wixCodeApi);
    const fullscreenEndPoint = isStore
      ? '/fullscreen-store-page'
      : '/fullscreen-page';
    return {
      onNavigate: url => wixCodeApi.location.to(url),
      scrollTo: wixCodeApi.window.scrollTo,
      queryParams: wixCodeApi.location.query,
      viewMode: wixCodeApi.window.viewMode,
      baseUrl: ProGalleryStore.getBaseUrl(
        wixCodeApi,
        ProGalleryStore.parseViewMode(wixCodeApi.window.viewMode),
      ),
      siteUrl: siteUrl,
      pageUrl: ProGalleryStore.getPageUrl(wixCodeApi),
      fullscreenUrl: siteUrl + fullscreenEndPoint,
      setItems: pgStore.setItems,
      instanceId: this.getInstanceId(),
      items: this.getItems(),
      biContext: this.getBiContext(),
      totalItemsCount: pgStore.totalItemsCount,
      gallerySettings: pgStore.settings,
      dateCreated: pgStore.dateCreated,
      galleryId: pgStore.galleryId,
      translations: translationUtils.translations,
      experiments: experimentsWrapper.experiments,
      setHeight: this.getSetHeightFunc(),
      getMoreItems: from => this.getMoreItems(from),
      reportAppLoaded: pgStore.reportAppLoaded,
      locale: this.getLocaleFromContext(),
    };
  }

  getReportAppLoaded() {
    return this.getPgStore().reportAppLoaded;
  }

  getDirectFullscreenPromise() {
    const { compId } = this.getControllerConfig();
    return directFullscreenHelper.getDirectFullscreenPromise(
      compId,
      this.getQueryParams(),
      this.getPgStore(),
      this.isSSR(),
    );
  }

  getViewPortPromise() {
    const { compId, wixCodeApi } = this.getControllerConfig();
    return (
      (this.isSSR() && wixCodeApi.window.getComponentViewportState(compId)) ||
      Promise.resolve({ in: true })
    );
  }

  initPgStore(APP_DEFINITION_ID, GALLERY_WIDGET_ID) {
    const controllerData = this.getControllerConfig();
    const {
      appParams: { instanceId, instance },
      config: { externalId },
      compId,
      wixCodeApi,
    } = controllerData;
    const { msid, platformServices, scopedGlobalSdkApis } = this.getContext();
    this.pgStore = new ProGalleryStore(
      {
        externalId,
        compId,
        instanceId,
        instance,
        msid,
      },
      wixCodeApi,
      platformServices,
      scopedGlobalSdkApis,
      APP_DEFINITION_ID,
      GALLERY_WIDGET_ID,
    );
  }

  getContext() {
    return this.context;
  }

  reportAppLoaded() {
    this.getPgStore().reportAppLoaded();
  }

  getArtStoreOOIApi() {
    const { platformUtilitiesApi } = this.getContext();
    const { compId, setProps, wixCodeApi } = this.getControllerConfig();
    return getArtStoreApi(
      platformUtilitiesApi.pubSub,
      wixCodeApi.window.openPersistentPopup,
      compId,
      setProps,
    );
  }

  getSetHeightFunc() {
    const { setProps } = this.getControllerConfig();
    return height => {
      setProps({
        dimensions: {
          height,
        },
      });
    };
  }

  getBiContext() {
    const controllerData = this.getControllerConfig();
    return {
      _msid: controllerData.platformAPIs.bi.metaSiteId,
      _appId: controllerData.appParams.appDefinitionId,
      _instanceId: controllerData.appParams.instanceId,
      _siteOwnerId: controllerData.platformAPIs.bi.ownerId,
      _siteMemberId: controllerData.platformAPIs.bi.siteMemberId || '',
      _visitorId: controllerData.platformAPIs.bi.visitorId,
      _viewMode: this.wixCodeApi.window.viewMode,
    };
  }

  getSetMetaTagsFunc() {
    return itemData => {
      this.getPgStore().setMetaTags(itemData, this.wixCodeApi);
    };
  }

  getOnLinkNavigationFunc() {
    return item => this.getPgStore().navigateToLink(item);
  }

  getOnItemClickedFunc(galleryWixCodeApi) {
    return (itemData, itemClickStyleParam) => {
      if (galleryWixCodeApi) {
        galleryWixCodeApi.onItemClicked(itemData);
      }
      this.getPgStore().onItemClicked(itemData, itemClickStyleParam);
    };
  }

  onItemClicked(galleryWixCodeApi, itemData, itemClickStyleParam) {
    if (galleryWixCodeApi) {
      galleryWixCodeApi.onItemClicked(itemData);
    }
    this.getPgStore().onItemClicked(itemData, itemClickStyleParam);
  }

  getConnectedProviders() {
    const proGalleryStore = this.getPgStore();
    const { setProps } = this.getControllerConfig();
    //code for  photo-albums - get motherSite instance if needed
    const queryParams = proGalleryStore.getQueryParams();
    if (queryParams && queryParams.motherSiteUrl) {
      console.log('getting connected provider for mother site');
      this.getConnectedProvidersOfMotherSite(
        queryParams.motherSiteUrl,
        proGalleryStore,
      );
    } else {
      proGalleryStore.getConnectedProvidersFromServer().then(res => {
        setProps({ connectedProviders: res });
      });
    }
  }

  getConnectedProvidersOfMotherSite(motherSiteUrl, proGalleryStore) {
    const { setProps } = this.getControllerConfig();
    const url = motherSiteUrl + '/_api/dynamicmodel';
    fetch(url).then(response =>
      response.json().then(responseJson => {
        const { clientSpecMap } = responseJson;
        const photoAlbumsAppDefId = '13ff8629-c1fc-e289-e81f-bc8c8968e9d6';
        if (clientSpecMap) {
          const key = Object.keys(clientSpecMap).find(
            _key => clientSpecMap[_key].appDefinitionId === photoAlbumsAppDefId,
          );
          if (key && clientSpecMap[key]) {
            const instance = clientSpecMap[key].instance;
            proGalleryStore
              .getConnectedProvidersFromServer(instance)
              .then(res => {
                setProps({ connectedProviders: res });
                this.getArtStoreOOIApi().updateConnectedProviders({
                  [res.providerId]: res,
                });
              });
          }
        }
      }),
    );
  }

  setPropsFunc(props) {
    const { setProps } = this.getControllerConfig();
    setProps(props);
  }

  getMoreItems(from) {
    const proGalleryStore = this.getPgStore();
    const { setProps } = this.getControllerConfig();
    if (proGalleryStore.itemsSrc !== 'wixCode') {
      proGalleryStore.getItemsFromServer(from).then(items => {
        proGalleryStore.addItems(items, from);
        setProps({
          items: proGalleryStore.getItems(),
        });
      });
    }
  }

  getControllerConfig() {
    return this.controllerConfigs;
  }

  setWarmupDataIfNeeded() {
    const { warmupData } = this.getControllerConfig();
    if (warmupData) {
      const proGalleryStore = this.getPgStore();
      proGalleryStore.setItems(warmupData.items);
      proGalleryStore.totalItemsCount = warmupData.totalItemsCount;
      experimentsWrapper.setExperiments(warmupData.experiments);
    }
  }

  getSetNewItemsFunc() {
    const pgStore = this.getPgStore();
    const { setProps } = this.getControllerConfig();
    const setNewItems = items => {
      const directFullscreenItem = directFullscreenHelper.getDirectFullscreenItemFromItemsList(
        this.getQueryParams(),
        items,
      );
      directFullscreenItem &&
        pgStore.setDirectFullscreenItem(directFullscreenItem);
      setProps({
        directFullscreenItem,
        wixCodeItems: items
          .filter(item => !!item.mediaUrl)
          .map(item => {
            return {
              ...item,
              metaData: JSON.stringify(item.metaData),
            };
          }),
      });
    };
    return setNewItems;
  }

  setNewStyleParams(manualStyleParams) {
    const { setProps } = this.getControllerConfig();
    setProps({ manualStyleParams });
  }

  setClickedIdx(clickedIdx) {
    const { setProps } = this.getControllerConfig();
    setProps({ clickedIdx });
  }

  isValidWidgetId(type) {
    const WIDGET_ARRAY = this.WIDGET_ID_ARRAY;
    return WIDGET_ARRAY.includes(type);
  }

  sentryReport(error) {
    this.sentryReporter.report(error);
  }

  getItems() {
    return this.getPgStore().getItems();
  }

  getSettings() {
    return this.getPgStore().settings;
  }

  getWatermark() {
    return JSON.parse(this.getSettings()).watermark;
  }

  getInstanceId() {
    return this.getControllerConfig().appParams.instanceId;
  }

  getSSRWorkerLog() {
    return this.getPgStore().getSSRWorkerLog();
  }

  static setTranslationsAndExperiments(scopedGlobalSdkApis, sentryReporter) {
    try {
      const translationsPromise = ProGalleryStore.getTranslations();
      const experimentsPromise = ProGalleryStore.getExperiments(
        scopedGlobalSdkApis,
      );
      Promise.all([experimentsPromise, translationsPromise])
        .catch(e => {
          if (sentryReporter && sentryReporter.report) {
            sentryReporter.report(e);
          }
          console.error('Waiting for promises failed', e);
        })
        .then(([experimentsRaw, translationsRaw]) => {
          translationUtils.setTranslations(translationsRaw);
          experimentsWrapper.setExperiments(experimentsRaw);
        });
    } catch (e) {
      if (sentryReporter && sentryReporter.report) {
        sentryReporter.report(e);
      }
      console.error('Getting page init data failed', e);
    }
  }

  static getInitAppForPageFunc(context) {
    return (
      initAppParam,
      platformUtilitiesApi,
      scopedGlobalSdkApis,
      platformServices,
    ) => {
      context.setContext({
        ...initAppParam,
        platformServices,
        scopedGlobalSdkApis,
        platformUtilitiesApi,
      });
      context.initSentry(initAppParam, scopedGlobalSdkApis, platformServices);
      CommonViewerScript.setTranslationsAndExperiments(
        scopedGlobalSdkApis,
        context.getSentry(),
      );
    };
  }

  isSSR() {
    return this.wixCodeApi.window.rendering.env === 'backend';
  }

  static getCreateControllers(controllerFunc, sentryReporter) {
    return controllerConfigs => {
      try {
        return controllerConfigs.map(function(config) {
          return Promise.resolve(controllerFunc(config));
        });
      } catch (e) {
        if (sentryReporter && sentryReporter.report) {
          sentryReporter.report(e);
        }
      }
    };
  }
}
